import { SvgAdd, SvgClock } from "@itwin/itwinui-icons-react";
import { Svg404 } from "@itwin/itwinui-illustrations-react";
import { FluidGrid, PageLayout } from "@itwin/itwinui-layouts-react";
import {
  Button,
  Flex,
  LabeledInput,
  MenuItem,
  Modal,
  ModalButtonBar,
  ModalContent,
  NonIdealState,
  SearchBox,
  Text,
  Tile,
} from "@itwin/itwinui-react";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useMemo, useState } from "react";
import { createSearchParams, useMatch, useNavigate, useSearchParams } from "react-router-dom";

import { useAuth } from "../../components/Auth/AuthProvider";
import { DefaultLoading } from "../../components/Loading";
import { useDebounce } from "../../hooks";
import type { IModelFull } from "../../types";
import { useViewIModelAction } from "../ViewRouter/IModelActions";
import { SelectIModelTitle } from "./components/SelectIModelTitle";

interface Action {
  key: string;
  icon: React.JSX.Element;
  onClick: (iModel: unknown) => void;
  children: React.ReactNode;
  sublabel?: React.ReactNode;
}

interface IModelTileProps {
  iModel: IModelFull;
  iModelActions?: Action[];
}

const IModelTile = ({ iModel, iModelActions }: IModelTileProps) => {
  const navigate = useNavigate();

  const { status, data } = useQuery({
    queryKey: [
      `/imodels/${iModel.id}/thumbnail`,
      {
        version: "v2",
      },
      "blob",
    ],
    meta: {
      Accept: "application/vnd.bentley.itwin-platform.v2+json",
    },
  });

  return (
    <Tile.Wrapper key={iModel.id} isLoading={status === "pending"}>
      <Tile.ThumbnailArea>
        <Tile.ThumbnailPicture
          onClick={() => {
            navigate(`imodel/${iModel.id}`);
          }}
          url={status === "success" ? URL.createObjectURL(data as Blob) : ""}
        />
      </Tile.ThumbnailArea>
      <Tile.Name>
        <Tile.NameLabel>
          <Tile.Action href={`${iModel.iTwinId}/imodel/${iModel.id}`}>{iModel?.displayName}</Tile.Action>
        </Tile.NameLabel>
      </Tile.Name>
      <Tile.ContentArea>
        <Tile.Description>{iModel?.description}</Tile.Description>
        <Tile.Metadata>
          <SvgClock /> {`Created on: ${new Date(iModel.createdDateTime!).toDateString()}`}
        </Tile.Metadata>
        <Tile.MoreOptions>
          {iModelActions?.map((action) => (
            <MenuItem
              key={action.key}
              startIcon={action.icon}
              onClick={() => action.onClick(iModel)}
              sublabel={action?.sublabel}
            >
              {action.children}
            </MenuItem>
          ))}
        </Tile.MoreOptions>
      </Tile.ContentArea>
    </Tile.Wrapper>
  );
};

type OrderBy = "createdDateTime" | "name";
type SortBy = "asc" | "desc";

export const SelectIModel = () => {
  const { accessToken } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams(
    createSearchParams({
      search: "",
      // orderBy: "createdDateTime",
      // sort: "asc",
    })
  );
  const debouncedSearchValue = useDebounce(searchParams.get("search") ?? "", 1000);

  const [openModal, setOpenModal] = useState(false);
  const [newIModelName, setNewIModelName] = useState("");

  const projectMatch = useMatch("/:section/itwin/:iTwinId/*");
  const iTwinId = projectMatch?.params.iTwinId ?? "";
  const PAGE_SIZE = 100;

  const { status, data, error, refetch, fetchNextPage, isFetchingNextPage, hasNextPage } = useInfiniteQuery<{
    iModels?: IModelFull[];
    _links?: { self: { href: string }; prev: { href: string }; next: { href: string } };
  }>({
    queryKey: [`/imodels/?iTwinId=${iTwinId}`],
    queryFn: async ({ pageParam }) => {
      const response = await fetch(
        `https://${globalThis.IMJS_URL_PREFIX}api.bentley.com/imodels/?iTwinId=${iTwinId}&$skip=${
          (pageParam as number) * PAGE_SIZE
        }&top=${PAGE_SIZE}${debouncedSearchValue ? `&$search=${debouncedSearchValue.trim()}` : ""}`,
        {
          headers: {
            Authorization: accessToken!,
            "Content-Type": "application/json",
            Prefer: "return=representation",
            Accept: "application/vnd.bentley.itwin-platform.v2+json",
          },
        }
      );
      return await response.json();
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, _allPages, lastPageParam: any) => {
      return lastPage._links?.next ? lastPageParam + 1 : undefined;
    },
  });

  useEffect(() => {
    void refetch(); // refetch anytime you change the search
  }, [debouncedSearchValue, refetch]);

  // console.log(data);

  const actions = useViewIModelAction({ iTwinId, forceRefresh: refetch });
  const iModelActions = useMemo(() => {
    const {
      viewAction,
      savedViewsAction,
      meshExportAction,
      manageVersionsAction,
      viewInDesignReviewAction,
      viewInNavigatorBackendAction,
      // changedElementsAction,
      cloneIModelAction,
      deleteIModelAction,
      editExtentsAction,
    } = actions;

    const iModelActions = [
      viewAction,
      savedViewsAction,
      manageVersionsAction,
      // changedElementsAction,
      cloneIModelAction,
      deleteIModelAction,
      editExtentsAction,
    ];

    if (accessToken) {
      const [, body] = accessToken.split(".");
      try {
        const claims = JSON.parse(atob(body));
        if (claims?.email?.includes("@bentley.com")) {
          iModelActions.push(meshExportAction);
          iModelActions.push(viewInDesignReviewAction);
          iModelActions.push(viewInNavigatorBackendAction);
        }
      } catch {}
    }

    return iModelActions;
  }, [actions, accessToken]);

  const createMutation = useMutation({
    mutationFn: async () => {
      await fetch(`https://${globalThis.IMJS_URL_PREFIX}api.bentley.com/imodels`, {
        method: "POST",
        headers: {
          Authorization: accessToken!,
          Accept: "application/vnd.bentley.itwin-platform.v2+json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          iTwinId,
          name: newIModelName,
        }),
      });
    },
  });

  return (
    <>
      <Modal
        isOpen={openModal}
        title={"Create an iModel"}
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <ModalContent>
          <LabeledInput
            placeholder="Enter iModel name..."
            label="Name"
            // setFocus={true}
            onChange={(event) => {
              const {
                target: { value },
              } = event;
              setNewIModelName(value);
            }}
            onKeyDown={async (event) => {
              if (event.key === "Enter") {
                await createMutation.mutateAsync();
                await refetch();
                setOpenModal(false);
              }
            }}
          />
        </ModalContent>
        <ModalButtonBar>
          <Button
            styleType="high-visibility"
            onClick={async () => {
              await createMutation.mutateAsync();
              await refetch();
              setOpenModal(false);
            }}
          >
            Create
          </Button>
        </ModalButtonBar>
      </Modal>
      <PageLayout className="idp-scrolling-container">
        <PageLayout.Header className={"idp-content-margins"}>
          <PageLayout.TitleArea>
            <SelectIModelTitle iTwinId={iTwinId} />
          </PageLayout.TitleArea>
          <PageLayout.ToolsArea
            left={
              <Button
                as={"button"}
                startIcon={<SvgAdd />}
                onClick={() => {
                  setOpenModal(true);
                }}
                styleType="high-visibility"
              >
                Create new iModel
              </Button>
            }
            right={
              <>
                {/* <ButtonGroup>
                  <IconButton
                    styleType={"borderless"}
                    title={"Sort Ascending"}
                    as={"button"}
                    onClick={() => {
                      setSearchParams((prev) => {
                        prev.set("sort", "asc");
                        return prev;
                      });
                    }}
                  >
                    <SvgSortUp />
                  </IconButton>
                  <IconButton
                    styleType={"borderless"}
                    title={"Sort Descending"}
                    as={"button"}
                    onClick={() => {
                      setSearchParams((prev) => {
                        prev.set("sort", "desc");
                        return prev;
                      });
                    }}
                  >
                    <SvgSortDown />
                  </IconButton>
                </ButtonGroup> */}
                <SearchBox>
                  <SearchBox.Input
                    placeholder={"Enter name or description of the iModel"}
                    value={searchParams.get("search") ?? ""}
                    onChange={(event) => {
                      const {
                        target: { value },
                      } = event;
                      setSearchParams((prev) => {
                        prev.set("search", value);
                        return prev;
                      });
                    }}
                    onKeyDown={async (event) => {
                      if (event.key === "Enter") {
                        await refetch();
                      }
                      if (event.key === "Escape") {
                        setSearchParams((prev) => {
                          prev.delete("search");
                          return prev;
                        });
                      }
                    }}
                  />
                  <SearchBox.Button
                    label={"Search"}
                    onClick={async () => {
                      await refetch();
                    }}
                  />
                </SearchBox>
              </>
            }
          />
        </PageLayout.Header>
        <PageLayout.Content style={{ height: "100%" }} className="idp-content-margins">
          {status === "pending" && <DefaultLoading />}
          {status === "error" && (
            <NonIdealState
              title="Error"
              description={error?.message}
              svg={<Svg404 />}
              actions={
                <Button styleType={"high-visibility"} onClick={() => refetch()}>
                  Retry
                </Button>
              }
            />
          )}
          {status === "success" && data?.pages && data.pages.length > 0 ? (
            <FluidGrid>
              {data.pages
                .map((page) =>
                  page.iModels?.map((iModel) => (
                    <div key={iModel.id}>
                      <IModelTile iModel={iModel} iModelActions={iModelActions} />
                    </div>
                  ))
                )
                .flat()}
            </FluidGrid>
          ) : (
            <Flex>
              <Text variant="body">No iModels found.</Text>
            </Flex>
          )}
          {hasNextPage && (
            <Flex alignItems="center" justifyContent="center" style={{ padding: "24px" }}>
              <Button disabled={isFetchingNextPage} onClick={() => fetchNextPage()}>
                Load more
              </Button>
            </Flex>
          )}
        </PageLayout.Content>
      </PageLayout>
    </>
  );
};
