import type { AccessToken } from "@itwin/core-bentley";
import React, { createContext, useCallback, useContext, useEffect, useState } from "react";

import { useConfig } from "../../config/ConfigProvider";
import { AuthClient } from "../../services/auth/AuthClient";

interface AuthContextValue {
  accessToken?: AccessToken;
  signOut: () => void;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthContext = createContext<AuthContextValue>({
  signOut: async () => {
    await AuthClient.signOut();
  },
});

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [accessToken, setAccessToken] = useState<AccessToken>();
  const { auth } = useConfig();

  useEffect(() => {
    const initAuth = async () => {
      if (!auth) {
        return;
      }
      if (!AuthClient.client) {
        // if not one of the deployed test apps with a valid auth client, use token server client
        // this is to get around preview deployments which will have a unique host which hasn't been whitelisted
        if (
          ![
            "localhost",
            // qa
            "itwin.netlify.app",
            "qa-viewer.itwin.dev",
            //prod
            "itwin.vercel.app",
            "viewer.itwin.dev",
          ].includes(window.location.hostname) ||
          !auth.clientId
        ) {
          const tokenServerAuthClient = await AuthClient.initializeTokenServerClient();
          const token = await tokenServerAuthClient.getAccessToken();
          setAccessToken(token);
        }
        const client = AuthClient.initialize(auth.clientId);
        client.onAccessTokenChanged.addListener((token?: AccessToken) => {
          setAccessToken(token);
        });
      }

      try {
        // attempt silent sign in
        await AuthClient.signInSilent();
      } catch (error) {
        // if silent sign in fails, have user manually sign in
        if (window.self !== window.top) {
          // if in an iframe (embed api)
          await AuthClient.signInPopup();
        } else {
          await AuthClient.signIn();
        }
      }
    };

    initAuth().catch(console.error);

    return () => {
      AuthClient.dispose();
    };
  }, [auth]);

  const signOut = useCallback(async () => {
    await AuthClient.signOut();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthContext");
  }
  return context;
};
