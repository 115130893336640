import { BrowserAuthorizationClient, isBrowserAuthorizationClient } from "@itwin/browser-authorization";
import type { ViewerAuthorizationClient } from "@itwin/web-viewer-react";

import { TokenServerAuthClient } from "./TokenServerClient";

export class AuthClient {
  private static _client?: ViewerAuthorizationClient;

  public static get client(): ViewerAuthorizationClient | undefined {
    return this._client;
  }

  public static initialize(clientId: string): ViewerAuthorizationClient {
    if (!this._client) {
      const scopes = [
        "imodelaccess:read", // gpb
        "general-purpose-imodeljs-backend",
        "itwinjs", // needed for internal backends
        "navigator-backend",
        "itwinjs-extensions:read",
        "itwin-platform",
      ];

      const redirectUri = `${window.location.origin}/signin-callback`;
      const postSignoutRedirectUri = window.location.origin;

      this._client = new BrowserAuthorizationClient({
        clientId,
        redirectUri,
        postSignoutRedirectUri,
        scope: scopes.join(" "),
        responseType: "code",
        authority: `https://${globalThis.IMJS_URL_PREFIX}ims.bentley.com`,
      });
    }
    return this._client;
  }

  public static async initializeTokenServerClient(): Promise<ViewerAuthorizationClient> {
    if (!this._client) {
      const client = new TokenServerAuthClient();
      await client.initialize();
      this._client = client;
    }
    return this._client;
  }

  public static async signIn(): Promise<void> {
    if (isBrowserAuthorizationClient(this._client)) {
      await this._client.signIn();
    }
  }

  public static async signInSilent(): Promise<void> {
    if (isBrowserAuthorizationClient(this._client)) {
      await this._client.signInSilent();
    }
  }

  public static async signInPopup(): Promise<void> {
    if (isBrowserAuthorizationClient(this._client)) {
      await this._client.signInPopup();
    }
  }

  public static async signOut(): Promise<void> {
    if (isBrowserAuthorizationClient(this._client)) {
      await this._client.signOut();
    }
  }

  public static dispose(): void {
    this._client = undefined;
  }

  // copied from https://github.com/iTwin/auth-clients/blob/main/packages/browser/src/Client.ts#L429
  public static async handleSigninCallback(): Promise<void> {
    const _client = new BrowserAuthorizationClient({} as any);
    const url = new URL(window.location.href);
    const nonce = url.searchParams.get("state");
    const storageEntry = window.localStorage.getItem(`oidc.${nonce}`);
    if (!storageEntry) {
      throw new Error("Could not load oidc settings from local storage. Ensure the client is configured properly");
    }

    const storageObject = JSON.parse(storageEntry);

    const transformed = {
      ...storageObject,
      clientId: storageObject.client_id,
      redirectUri: storageObject.redirect_uri,
      authority: storageObject.authority,
    };

    _client["_basicSettings"] = transformed; // basicSettings is protected
    await _client.handleSigninCallback();
  }
}
