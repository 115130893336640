import React from "react";
import Loadable from "react-loadable";
import { createBrowserRouter, Route, RouterProvider, Routes, useParams } from "react-router-dom";

import { Layout } from "../components/Layout";
import { Loading } from "../components/Loading";
import { Redirect } from "./Redirect";
import { ViewRouter } from "./ViewRouter/ViewRouter";

// for redirecting from deprecated routes
const RedirectToSibling = ({ sibling }: { sibling: string }) => {
  const params = useParams();
  const newPath = `../${sibling}/${params["*"]}`;
  return <Redirect to={newPath} />;
};

// const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
//   const { data, status } = useQuery<{ permissions: string[] }>({
//     queryKey: ["/accesscontrol/itwins/78202ffd-272b-4207-a7ad-7d2b1af5dafc/permissions"],
//   });

//   return (
//     <>
//       {status === "pending" && (
//         <Loading
//           isLoading={false}
//           pastDelay={true}
//           timedOut={false}
//           error={undefined}
//           retry={() => {
//             /* no op */
//           }}
//         />
//       )}
//       {status === "error" && <Navigate to="/view" replace />}
//       {/* {status === "success" && children} */}
//       {status === "success" &&
//       ["administration_manage_roles", "ADMINACCESS"].some((permission) => data.permissions?.includes(permission)) ? (
//         children
//       ) : (
//         <Navigate to="/view" replace />
//       )}
//     </>
//   );
// };

const Root = () => {
  const LazySavedViewsRouter = Loadable({
    loader: () => import(/* webpackChunkName: "SavedViewsRouter" */ "./SavedViewsRouter/SavedViewsRouter"),
    loading: Loading,
  });

  const LazyManageVersionsRouter = Loadable({
    loader: () => import(/* webpackChunkName: "ManageVersionsRouter" */ "./ManageVersionsRouter/ManageVersionsRouter"),
    loading: Loading,
  });

  const LazyManageMembersRouter = Loadable({
    loader: () => import(/* webpackChunkName: "ManageMembersRouter" */ "./MembersRouter/Router"),
    loading: Loading,
  });

  const LazyMeshExportsRouter = Loadable({
    loader: () => import(/* webpackChunkName: "MeshExportsRouter" */ "./MeshExportRouter/Router"),
    loading: Loading,
  });

  const LazyCesiumRouter = Loadable({
    loader: () => import(/* webpackChunkName: "CesiumRouter" */ "./CesiumRouter/CesiumRouter"),
    loading: Loading,
  });

  // const LazyStudioAppStoreRouter = Loadable({
  //   loader: () => import(/* webpackChunkName: "StudioAppStoreRouter" */ "./Studio/StudioStoreRouter"),
  //   loading: Loading,
  // });

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="view/cesium/*" element={<LazyCesiumRouter />} />

        <Route path="view/*" element={<ViewRouter />} />
        <Route path="members/*" element={<LazyManageMembersRouter />} />

        {/* deprecated projects route */}
        <Route path="view/project/*" element={<RedirectToSibling sibling="view/itwin" />} />
        <Route path="savedviews/project/*" element={<RedirectToSibling sibling="savedviews/itwin" />} />

        {/* deprecated `savedviews` route for `saved-views` */}
        {/* <Route path="savedviews/*" element={<LazySavedViewsRouter accessToken={accessToken} />} /> */}

        <Route path="savedviews/*" element={<RedirectToSibling sibling="saved-views" />} />
        <Route path="saved-views/*" element={<LazySavedViewsRouter />} />

        <Route path="manage-versions/*" element={<LazyManageVersionsRouter />} />
        <Route path="mesh-export/*" element={<LazyMeshExportsRouter />} />

        {/* Protected Route that check for permissions before rendering the children */}
        {/* <Route
          path="mango"
          element={
            <ProtectedRoute>
              <LazyStudioAppStoreRouter />
            </ProtectedRoute>
          }
        /> */}

        <Route path="/" element={<Redirect to="/view" />} />
      </Route>
    </Routes>
  );
};

const router = createBrowserRouter([{ path: "*", element: <Root /> }], {
  // unstable_dataStrategy({ request, matches }) {
  //   console.log("Processing routes", request);
  //   return Promise.all(
  //     matches.map(async (match) => {
  //       console.log(`Processing route ${match.route.id}`);
  //       // Don't override anything - just resolve route.lazy + call loader
  //       const result = await match.resolve();
  //       console.log(`Done processing route ${match.route.id}`);
  //       return result;
  //     })
  //   );
  // },
});

export const MainRouter = () => {
  return <RouterProvider router={router} />;
};
