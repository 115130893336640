import React, { createContext, useContext, useEffect, useState } from "react";

import type { DemoPortalConfig } from ".";
import { getConfig } from ".";

export interface ConfigProviderProps {
  children: React.ReactNode;
}

const defaultConfig: DemoPortalConfig = {
  urlPrefix: "",
  auth: {
    clientId: "",
  },
  mapLayers: {
    bingMapsKey: "",
    mapboxAccessToken: "",
  },
  cesiumIonKey: "",
};

const ConfigContext = createContext<DemoPortalConfig>({});

export const ConfigProvider = ({ children }: ConfigProviderProps) => {
  const [appConfig, setAppConfig] = useState<DemoPortalConfig>();

  useEffect(() => {
    const config = getConfig();
    setAppConfig(config);
  }, []);

  return <ConfigContext.Provider value={{ ...appConfig }}>{children}</ConfigContext.Provider>;
};

export const useConfig = () => {
  const context = useContext(ConfigContext);
  if (context === undefined || context === defaultConfig) {
    throw new Error("useConfig must be used within a ConfigContext");
  }
  return context;
};
