interface DemoPortalAuthConfig {
  clientId: string;
}

interface MapLayersConfig {
  bingMapsKey?: string;
  mapboxAccessToken?: string;
}

export interface DemoPortalConfig {
  auth?: DemoPortalAuthConfig;
  urlPrefix?: string;
  mapLayers?: MapLayersConfig;
  cesiumIonKey?: string;
}

export const getConfig = (): DemoPortalConfig => {
  return {
    urlPrefix: globalThis.IMJS_URL_PREFIX,
    auth: {
      clientId: process.env.IMJS_AUTH_CLIENT_CLIENT_ID ?? "",
    },
    mapLayers: {
      bingMapsKey: process.env.IMJS_BING_MAPS_KEY ?? "",
      mapboxAccessToken: process.env.IMJS_MAPBOX_ACCESS_TOKEN ?? "",
    },
    cesiumIonKey: process.env.IMJS_CESIUM_ION_KEY ?? "",
  };
};
