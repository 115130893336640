// import { getWebInstrumentations, initializeFaro } from "@grafana/faro-web-sdk";
// import { TracingInstrumentation } from "@grafana/faro-web-tracing";
import "@itwin/itwinui-layouts-css/styles.css";
import "@itwin/itwinui-react/styles.css";
import React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";
import "./index.scss";
import { AuthClient } from "./services/auth/AuthClient";
import * as serviceWorker from "./serviceWorker";

// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
globalThis.IMJS_URL_PREFIX = process.env.IMJS_URL_PREFIX || "";

// export const faro =
//   window.location.hostname !== "localhost"
//     ? initializeFaro({
//         url: process.env.IMJS_FARO_COLLECTOR_URL ?? "",
//         app: {
//           name: "Arun's iTwin app",
//           version: "1.0.0",
//           environment: globalThis.IMJS_URL_PREFIX || "production",
//         },
//         instrumentations: [
//           // Mandatory, overwriting the instrumentations array would cause the default instrumentations to be omitted
//           ...getWebInstrumentations(),

//           // Initialization of the tracing package.
//           // This packages is optional because it increases the bundle size noticeably. Only add it if you want tracing data.
//           new TracingInstrumentation(),
//         ],
//         // dedupe: true,
//         batching: {
//           enabled: true,
//         },
//       })
//     : undefined;

// Do not render full application if we are handling OIDC callback
const redirectUrl = new URL(`${window.location.origin}/signin-callback`);
if (redirectUrl.pathname === window.location.pathname) {
  AuthClient.handleSigninCallback().catch(console.error);
} else {
  const container = document.getElementById("root");
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(container!);
  root.render(
    // <React.StrictMode>
    <App />
    // </React.StrictMode>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
