import React from "react";
import { Route, Routes } from "react-router-dom";

import { Redirect } from "../Redirect";
import { SelectIModel } from "./SelectIModel";
import { SelectProject } from "./SelectProject";

export const SelectionRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<SelectProject />} />
      <Route path="itwin" element={<Redirect to="../" />} />
      <Route path="itwin/:iTwinId" element={<SelectIModel />} />
      <Route path="itwin/:iTwinId/imodel" element={<Redirect to="../../../itwin/:iTwinId/" />} />
    </Routes>
  );
};
