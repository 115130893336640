import { PageLayout } from "@itwin/itwinui-layouts-react";
import type { ThemeType } from "@itwin/itwinui-react";
import { ThemeProvider } from "@itwin/itwinui-react";
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import { Header } from "./Header/Header";

export const Layout = () => {
  const [theme, setTheme] = useState<ThemeType>((localStorage.getItem("THEME") as ThemeType) ?? "os");
  useEffect(() => {
    localStorage.setItem("THEME", theme);
  }, [theme]);

  return (
    <ThemeProvider
      theme={theme}
      themeOptions={{
        applyBackground: true,
        // highContrast: theme === "dark"
      }}
    >
      <PageLayout>
        <PageLayout.Header>
          <Header
            theme={theme}
            onThemeChange={() => {
              setTheme((theme) => (theme === "light" ? "dark" : "light"));
            }}
          />
        </PageLayout.Header>
        <PageLayout.Content>{<Outlet />}</PageLayout.Content>
      </PageLayout>
    </ThemeProvider>
  );
};
