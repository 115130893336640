import { SvgIModelLoader } from "@itwin/itwinui-illustrations-react";
import React from "react";
import type { LoadingComponentProps } from "react-loadable";

export const DefaultLoading = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <SvgIModelLoader style={{ height: "64px" }} />
    </div>
  );
};

export const Loading = (props: LoadingComponentProps) => {
  if (props.error) {
    console.error(props.error);
    return <div>Error!</div>;
  } else if (props.pastDelay) {
    return <DefaultLoading />;
  } else {
    return null;
  }
};
