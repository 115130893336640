import { Avatar, DropdownMenu, getUserColor, IconButton, MenuItem, Text } from "@itwin/itwinui-react";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";

import { useConfig } from "../../config/ConfigProvider";
// import { faro } from "../../index";
import "./HeaderUserIcon.scss";

interface UserInfo {
  displayName: string;
  givenName: string;
  surname: string;
  email: string;
  organizationName: string;
}

interface HeaderUserIconProps {
  handleLogout?: () => void;
}

type AvatarProps = React.ComponentProps<typeof Avatar>;

export const HeaderUserIcon = ({ handleLogout }: HeaderUserIconProps) => {
  const [userIconProps, setUserIconProps] = useState<Partial<AvatarProps>>({});
  const { auth } = useConfig();

  const {
    data: { user },
  } = useQuery<{ user: UserInfo }>({
    queryKey: ["/users/me"],
    enabled: !!auth?.clientId,
    initialData: {
      user: {
        displayName: "",
        givenName: "",
        surname: "",
        email: "",
        organizationName: "",
      },
    },
  });

  useEffect(() => {
    setUserIconProps({
      abbreviation: (user?.givenName.toLocaleUpperCase()?.[0] ?? "") + (user?.surname.toLocaleUpperCase()?.[0] ?? ""),
      backgroundColor: getUserColor(user?.displayName ?? "Unknown"),
    });
  }, [user]);

  // useEffect(() => {
  //   if (!user) {
  //     faro?.api.resetUser();
  //   } else {
  //     faro?.api.setUser({
  //       email: user.email,
  //     });
  //   }
  // }, [user]);

  return (
    <DropdownMenu
      menuItems={(close) => [
        <div key={"description"} className={"user-panel"}>
          <Text variant="body" style={{ marginBottom: 5 }} key={"name"}>
            {user?.givenName ?? ""} {user?.surname ?? ""}
          </Text>
          <Text variant="small" key={"email"}>
            {user?.email ?? ""}
            <br />
            {user?.organizationName ?? ""}
          </Text>
        </div>,
        <MenuItem
          key={"logout"}
          onClick={() => {
            close();
            handleLogout?.();
          }}
        >
          Sign out
        </MenuItem>,
      ]}
    >
      <IconButton styleType={"borderless"}>
        <Avatar size="small" className={"user-icon-code-order-fix"} {...userIconProps} />
      </IconButton>
    </DropdownMenu>
  );
};
