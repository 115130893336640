import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import { isVercelToolbarMounted, mountVercelToolbar, unmountVercelToolbar } from "@vercel/toolbar";
import React, { useEffect } from "react";

import { MainRouter } from "../routers/MainRouter";
import { useAuth } from "./Auth/AuthProvider";
import { DefaultLoading } from "./Loading";

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import(/* webpackChunkName: "react-query-devtools" */ "@tanstack/react-query-devtools/production").then((d) => ({
    default: d.ReactQueryDevtools,
  }))
);

export const MainApp = () => {
  const { accessToken } = useAuth();
  const [showDevtools, setShowDevtools] = React.useState(false);

  useEffect(() => {
    // @ts-expect-error calling window.toggleDevtools() will download the devtools bundle and show them
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);

  // useEffect(() => {
  //   if (window.location.hostname !== "localhost" && accessToken) {
  //     const [, body] = accessToken.split(".");
  //     try {
  //       const claims = JSON.parse(atob(body));
  //       if (claims?.email?.includes("Arun.George@bentley.com")) {
  //         if (!isVercelToolbarMounted()) {
  //           mountVercelToolbar();
  //         }
  //       }
  //     } catch {}
  //   }
  //   return () => {
  //     if (isVercelToolbarMounted()) {
  //       unmountVercelToolbar();
  //     }
  //   };
  // }, [accessToken]);

  return (
    <QueryClientProvider
      client={
        new QueryClient({
          defaultOptions: {
            queries: {
              queryFn: async ({ queryKey }) => {
                const [endpoint, options, responseType] = queryKey as [
                  string,
                  { accessToken?: string; version: string } | undefined,
                  "arraybuffer" | "blob" | "formData" | "json" | "text" | undefined
                ];
                const response = await fetch(`https://${globalThis.IMJS_URL_PREFIX}api.bentley.com${endpoint}`, {
                  headers: {
                    Authorization: accessToken!,
                    // "Content-Type": "application/json",
                    Prefer: "return=representation",
                    Accept: `application/vnd.bentley.itwin-platform.${options?.version ?? "v1"}+json`,
                  },
                });
                if (!response.ok) {
                  throw new Error("Network response was not ok");
                }

                switch (responseType) {
                  case "arraybuffer":
                    return await response.arrayBuffer();
                  case "blob":
                    return await response.blob();
                  case "formData":
                    return await response.formData();
                  case "text":
                    return await response.text();
                  case "json":
                  default:
                    return await response.json();
                }
              },
              enabled: !!accessToken,
              retry: false,
            },
          },
        })
      }
    >
      {!!accessToken ? (
        <MainRouter />
      ) : (
        <div style={{ height: "100vh" }}>
          <DefaultLoading />
        </div>
      )}
      {showDevtools && (
        <React.Suspense fallback={null}>
          <ReactQueryDevtoolsProduction />
        </React.Suspense>
      )}
    </QueryClientProvider>
  );
};
