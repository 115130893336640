import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import React, { useEffect } from "react";

import { AuthProvider } from "./components/Auth/AuthProvider";
import { MainApp } from "./components/MainApp";
import { ConfigProvider } from "./config/ConfigProvider";

const App: React.FC = () => {
  useEffect(() => {
    const abortController = new AbortController();
    const suppressResizeObserverListener = (e: ErrorEvent) => {
      if (e.message.includes("ResizeObserver")) {
        const resizeObserverErrDiv = document.getElementById("webpack-dev-server-client-overlay-div");
        const resizeObserverErr = document.getElementById("webpack-dev-server-client-overlay");
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    };
    window.addEventListener("error", suppressResizeObserverListener, { signal: abortController.signal });
    return () => abortController.abort();
  }, []);

  return (
    <>
      <ConfigProvider>
        <AuthProvider>
          <MainApp />
        </AuthProvider>
      </ConfigProvider>
      <Analytics />
      <SpeedInsights />
    </>
  );
};

export default App;
