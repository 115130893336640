import React, { useEffect } from "react";
import Loadable from "react-loadable";
import { Route, Routes, useParams } from "react-router-dom";

import { Loading } from "../../components/Loading";
import { SelectionRouter } from "../SelectionRouter/SelectionRouter";

export const ViewRouter = () => {
  const LazyViewer = Loadable({
    loader: () => import(/* webpackChunkName: "Viewer", webpackPrefetch: true */ "./Viewer"),
    loading: Loading,
  });

  useEffect(() => {
    LazyViewer.preload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      <Route path="*" element={<SelectionRouter />} />
      {[
        "itwin/:iTwinId/imodel/:iModelId",
        "itwin/:iTwinId/imodel/:iModelId/changeset/:changesetId",
        "itwin/:iTwinId/imodel/:iModelId/version/:namedVersionId",
        "itwin/:iTwinId/imodel/:iModelId/savedview/:savedviewId",
        "itwin/:iTwinId/imodel/:iModelId/mesh-export/:meshExportId",
      ].map((path) => (
        <Route key={path} path={path} element={<LazyViewer />} />
      ))}
      <Route
        key={"design-review"}
        path={"design-review/itwin/:iTwinId/imodel/:iModelId"}
        element={<DesignReviewViewer />}
      />
    </Routes>
  );
};

const DesignReviewViewer = () => {
  const { iTwinId, iModelId } = useParams();
  return (
    <iframe
      title={"Design Review"}
      height={"100%"}
      width={"100%"}
      referrerPolicy={"no-referrer"}
      seamless={true}
      src={`https://${globalThis.IMJS_URL_PREFIX}connect-imodelweb.bentley.com/imodeljs/?projectId=${iTwinId}&iModelId=${iModelId}`}
    />
  );
};
