import type { AccessToken } from "@itwin/core-bentley";
import { BeEvent } from "@itwin/core-bentley";
import type { ViewerAuthorizationClient } from "@itwin/web-viewer-react";

export class TokenServerAuthClient implements ViewerAuthorizationClient {
  public readonly onAccessTokenChanged = new BeEvent<(token: AccessToken) => void>();
  protected _accessToken?: AccessToken;

  public async initialize() {
    const tokenUrl = `https://itv-proxy-fn.vercel.app/api/token?prefix=${globalThis.IMJS_URL_PREFIX}`;
    try {
      const res = await fetch(tokenUrl);
      if (res.ok) {
        const { token_type, access_token } = await res.json(); // todo extract exp time and refetch a new token
        const accessToken = `${token_type} ${access_token}`;
        this._accessToken = accessToken;
        this.onAccessTokenChanged.raiseEvent(accessToken);
      }
    } catch (err) {
      console.log(err);
    }
  }

  public async getAccessToken(): Promise<AccessToken> {
    if (!this._accessToken) {
      throw new Error("Cannot get access token");
    }
    return this._accessToken;
  }
}
